//--------------------------------------------------------------------------------------------------
//# Imports

import React from 'react'

import './break_signup.css'

import early_access_button from '../../image/get-early-access-button.webp'







//--------------------------------------------------------------------------------------------------
//# Variables











//AppStart
const Break_Signup = () => {

//--------------------------------------------------------------------------------------------------
//# Functions

  function mouseOver(event) {
    let element = document.getElementById(event.target.id);
    element.style.transform = 'scale(1.4)';
  };

  function mouseLeave(event) {
    let element = document.getElementById(event.target.id);
    element.style.transform = 'scale(1.0)';
  };

  function handleClick(event) {
    let element_ID = event.target.id;
    window.open("https://evanon.earth/alchmbetasignup-siteref", "_blank", "noopener noreferrer");
  };





//--------------------------------------------------------------------------------------------------
//# HTML

  return (
    <div className='break_signup'>
      <div>
        <div className='break_signupEarlyAccessContainer'>
          <img id='break_signupEarlyAccessButton' className='break_signupEarlyAccessButton' data-aos="zoom-in" data-aos-delay="0" src={early_access_button} onMouseOver={mouseOver} onMouseLeave={mouseLeave} onClick={handleClick}/>
        </div>
      </div>
    </div>
  )
}

export default Break_Signup