//--------------------------------------------------------------------------------------------------
//# Imports

import React, { useEffect, useState } from 'react';
import Aos from "aos";
import "aos/dist/aos.css";

import tokenoverview_image from '../../image/tokenoverview-image.webp'
import tokenoverview_video from '../../image/tokenoverview-video.mp4'

import './tokenoverview.css'











//--------------------------------------------------------------------------------------------------
//# Variables

const delay_gap = 300;
let reverse_interval;
let mobile = window.innerWidth <= 600;
let contentLoaded = true;
let playing_forward = false;

//AppStart
const TokenOverview = () => {











//--------------------------------------------------------------------------------------------------
//# Functions

  //// This section and conditional HTML seems to fix the issue where animations wouldn't play, and seemed 
  //// to have already played and loaded before the user scrolled to them.
  // const [contentLoaded, setContentLoaded] = useState(false);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     // Simulate async data fetching
  //     await new Promise((resolve) => setTimeout(resolve, 1000));
  //     setContentLoaded(true);
  //   };
  //   fetchData();
  // }, []);

  // useEffect(() => {
  //   if (contentLoaded) {
  //     Aos.refresh(); // Refresh AOS after content is loaded
  //   }
  // }, [contentLoaded]);
  //

  // Reset AOS timings after animation finishes so that mouseover animations are responsive
  useEffect(() => {
    Aos.init({ duration: 2000 });
    setTimeout(() => {
      const video_element = document.getElementById("tokenOverviewVideo");
      // video_element.style.transform = 'scale(1.0)';
      const helper_text = document.getElementById('tokenOverviewHelperText');
        video_element.addEventListener('mouseenter', () => {
          helper_text.style.transition = 'opacity 1.5s ease-in-out';
          helper_text.style.opacity = "0";
          // helper_text.style.transform = 'scale(1.0)';
        });
        video_element.addEventListener('mouseleave', () => {
          helper_text.style.transition = 'opacity 1.5s ease-in-out';  
          helper_text.style.opacity = "1";
          // helper_text.style.transform = 'scale(1.0)';
        });
      }, 1000);  // Match to AOS duration
  }, []);

  
//// This section and conditional HTML seems to fix the issue where animations wouldn't play, and seemed 
//// to have already played and loaded before the user scrolled to them.
  // useEffect(() => {
  //   const fetchData = async () => {
  //     // Simulate async data fetching
  //     await new Promise((resolve) => setTimeout(resolve, 1000));
  //     setContentLoaded(true);
  //   };
  //   fetchData();
  // }, []);

  // useEffect(() => {
  //   if (contentLoaded) {
  //     document.getElementById("tokenOverviewVideo").style.transform = 'scale(1.0)';
  //   }
  // }, [contentLoaded]);

  // useEffect(() => {
  //   Aos.init({ duration: 2000 });
  //   document.getElementById("tokenOverviewVideo").style.transform = 'scale(1.0)';
  //   const helper_text = document.getElementById('tokenOverviewHelperText');
  //   setTimeout(() => {
  //     helper_text.addEventListener('mouseenter', () => {
  //       helper_text.style.transition = 'transform .5s ease-in-out';
  //       helper_text.style.opacity = "0%";
  //       helper_text.style.transform = 'scale(1.0)';
  //     });
  //     helper_text.addEventListener('mouseleave', () => {
  //         helper_text.style.transform = 'scale(1.0)';
  //         helper_text.style.transition = 'transform .5s ease-in-out';
  //         helper_text.style.opacity = "100%";
  //     });
  //   }, 1000);  // Match to AOS duration
  // }, []);





  // function mouseOver(event) {
  //   // let element = document.getElementById(event.target.id);
  //   const image_element = document.getElementById("tokenOverviewImage");
  //   const video_element = document.getElementById("tokenOverviewVideo");
  //   // document.getElementById('tokenOverviewImage').style.display = "none";
  //   video_element.style.transform = 'scale(1.0)';
  //   image_element.style.opacity = "0%";
  //   video_element.style.opacity = "100%";
  //   // document.getElementById('tokenOverviewVideo').style.transform = 'scale(1.1)';
  //   video_element.play();
  // }
  
  // function mouseLeave(event) {
  //   // let element = document.getElementById(event.target.id);
  //   const image_element = document.getElementById("tokenOverviewImage");
  //   const video_element = document.getElementById("tokenOverviewVideo");
  //   // element.style.transform = 'scale(1.0)';
  //   video_element.style.transform = 'scale(1.0)';
  //   image_element.style.opacity = "100%";
  //   video_element.style.opacity = "0%";
  // }

  // if (mobile) {
  //   document.getElementById("tokenOverviewVideo").style.transform = 'scale(1.0)';
  // }



  function mouseOver(event) {
    if (!mobile) {
      playForward();
    };
  };
  
  function mouseLeave(event) {
    playReverse();
  };

  function handleClick(event) {
    console.log('onClick:', document.getElementById(event.target.id));
    if (mobile) {
      if (!playing_forward) {
        playForward();
      } else {
        playReverse();
      };
    };
  };


  function playForward () {
    const video_element = document.getElementById("tokenOverviewVideo");
    clearInterval(reverse_interval);
    video_element.play()
      .catch(error => {
        if (error.name === "NotAllowedError") {
          video_element.controls = true;
        }
      }
    );
    
    video_element.controls = false;
    playing_forward = true;

    // Hide helper text during playback
    document.getElementById('tokenOverviewHelperText').style.opacity = "0%";
  }
    

  function playReverse() {
    const video_element = document.getElementById("tokenOverviewVideo");
    video_element.pause();
    clearInterval(reverse_interval);  // Prevent multiple intervals

    reverse_interval = setInterval(() => {
      if (video_element.currentTime <= 0) {
        clearInterval(reverse_interval); // Stop when video reaches the beginning
      } else {
        video_element.currentTime -= 0.025;  // Reverse playback
      };
    }, 25);

    video_element.controls = false;
    playing_forward = false;

    // Reveal helper text after playback
    document.getElementById('tokenOverviewHelperText').style.opacity = "100%";
  };





//--------------------------------------------------------------------------------------------------
//# HTML

  return (
    <div className='tokenOverview'>
      {!contentLoaded ? (
        <p>Loading...</p>
      ) : (
      <div className="tokenOverview">
      <div className='tokenOverviewLeftContainer'>
        <div className='tokenOverviewImageContainer' id='tokenOverviewImageContainer'>
          {/* <img data-aos="flip-left" src={tokenoverview_image} alt='' className='tokenOverviewImage' id='tokenOverviewImage' onMouseOver={mouseOver} onMouseLeave={mouseLeave}/> */}
          <video data-aos="flip-left" src={tokenoverview_video} alt='' className='tokenOverviewVideo' id='tokenOverviewVideo' onMouseOver={mouseOver} onMouseLeave={mouseLeave} onClick={handleClick}
          muted playsInline poster={tokenoverview_image} preload="metadata"/>
        </div>
        <div className='tokenOverviewHelperTextContainer'> 
          {mobile ? (
            <div className='tokenOverviewHelperText' id='tokenOverviewHelperText' data-aos="fade-left" data-aos-delay={6 * delay_gap}>↑ Tap to Discover</div>
          ) : (
            <div className='tokenOverviewHelperText' id='tokenOverviewHelperText' data-aos="fade-right" data-aos-delay={6 * delay_gap}>↑ Mouse over to Discover</div>
          )}
        </div>
      </div>
      <div className='tokenOverviewRightContainer'>
        <div className='tokenOverviewTextContainer'>
          <div className='tokenOverviewText tokenOverviewTitle tokenOverviewTextTop' data-aos="fade-down" data-aos-delay={1 * delay_gap}>Harvest from the Planets</div>
          <div  className='tokenOverviewText' data-aos="fade-down-left" data-aos-delay={2 * delay_gap}>• Collect Spirit, Matter, Essence and Substance tokens <span style={{ color: 'var(--color-main)' }}>daily</span></div>
          <div  className='tokenOverviewText' data-aos="fade-left" data-aos-delay={3 * delay_gap}>• Earn more by <span style={{ color: 'var(--color-main)' }}>aligning</span> your alchemy with your environment.</div>
          <div  className='tokenOverviewText' data-aos="fade-up-left" data-aos-delay={4 * delay_gap}>• Get <span style={{ color: 'var(--color-main)' }}>rewarded</span> for mastering astrology, astronomy, and alchemy.</div>
          <div className='tokenOverviewText  tokenOverviewTextBottom' data-aos="fade-up" data-aos-delay={5 * delay_gap} >• Synthesize tokens and elements to unlock new insights into your <span style={{ color: 'var(--color-main)' }}>astral self</span>.</div>
        </div>
      </div>
      </div>
    )}
    </div>
  )
}

export default TokenOverview
