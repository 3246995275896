//--------------------------------------------------------------------------------------------------
//# Imports

import React from 'react'

import './alchmizeroverview.css'

import alchmizer_overview_image from '../../image/alchmizer-image.webp'
import alchmizer_overview_image_arrows from '../../image/alchmizer-image-arrows.webp'










//--------------------------------------------------------------------------------------------------
//# Variables

let alchmizer_image = alchmizer_overview_image;
const delay_gap = 200;
let mobile = window.innerWidth <= 600;

//AppStart
const AlchmizerOverview = () => {











//--------------------------------------------------------------------------------------------------
//# Functions

  function mouseOver(event) {
    let element = document.getElementById(event.target.id);
    if (event.target.id === 'alchmizerOverviewImage') {
      alchmizer_image = alchmizer_overview_image_arrows;
      element.src = alchmizer_image;
      element.style.transform = 'scale(1.06)';
    } else if (event.target.id === 'alchmizerPrivacyText') {
      element.innerHTML = `All birth information ingested by <span class="mainColor">The Alchmizer</span> is encrypted, and viewable only by its owner. All of its data and art outputs are authentically owned by the individual who generated them, and cannot be seen by others unless shared.`
      element.style.fontFamily = 'Sequel100Black-45';
      if (mobile) {
        element.style.fontSize = '12.5px';
        element.style.padding = "1.75% 0% 6.75% 0%";
        element.style.marginTop = "-5%";
      } else {
        element.style.fontSize = '17px';
      }
      element.style.color = 'var(--color-lightyellow)';
    }
  }
  
  function mouseLeave(event) {
    let element = document.getElementById(event.target.id);
    if (event.target.id === 'alchmizerOverviewImage') {
      alchmizer_image = alchmizer_overview_image;
      element.src = alchmizer_image;
      element.style.transform = 'scale(1.0)';
    } else if (event.target.id === 'alchmizerPrivacyText') {
      element.textContent = '- ENCRYPTED -';
      element.style.fontFamily = 'Courier New, monospace';
      if (mobile) {
        element.style.fontSize = '40px';
        element.style.padding = "15% 0% 15% 0%";
        element.style.marginTop = "-10%";
      } else {
        element.style.fontSize = '68px';
      }
      element.style.color = '#757575';
    }
  }

  return (
    <div className='alchmizerOverview'>
      <div className='alchmizerOverviewContainer'>
        <div className='alchmizerOverviewImageContainer'>
          <img data-aos="flip-right" src={alchmizer_image} alt='' className='alchmizerOverviewImage' id='alchmizerOverviewImage' onMouseOver={mouseOver} onMouseLeave={mouseLeave}/>
        </div>
        <div className='alchmizerOverviewTextContainer'>
          <div className='alchmizerOverviewTitleText' data-aos="zoom-out-up" data-aos-delay={1 * delay_gap}>At the core of Alchm's technology is <span style={{ color: 'var(--color-main)' }}>The Alchmizer</span>.
            <br />
            {mobile && <br />}
            This complex system of algorithms is made of 3 parts:
          </div>
          <br></br>
          <div className='alchmizerOverviewText' data-aos="fade-right" data-aos-delay={2 * delay_gap}><span style={{ color: 'var(--color-main)' }}>The Astrologizer</span> - derives a complete astrological profile from birth information.</div>
          <div className='alchmizerOverviewText' data-aos="fade-left" data-aos-delay={3 * delay_gap}><span style={{ color: 'var(--color-main)' }}>The Alchemizer</span> - derives alchemical nature and properties from astrological profile.</div>
          <div className='alchmizerOverviewText' data-aos="fade-right" data-aos-delay={4 * delay_gap}><span style={{ color: 'var(--color-main)' }}>The Imaginizer</span> - generates personalized art, charts, and insights using the derived astrological & alchemical information.</div>
          <br></br>
          <div className='alchmizerOverviewPrivacyText alchmizerOverviewText' id='alchmizerPrivacyText' onMouseOver={mouseOver} onMouseLeave={mouseLeave}
            style={{
              color: '#757575', 
              fontSize: mobile ? '40px' : '68px',
              fontFamily: 'Courier New, monospace'
            }}>- ENCRYPTED -</div>
        </div>
      </div>
    </div>
  )
}

export default AlchmizerOverview