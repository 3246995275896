//--------------------------------------------------------------------------------------------------
//# Imports

import React from 'react'

import './break_commune.css'









//--------------------------------------------------------------------------------------------------
//# Variables











//AppStart
const Break_Commune = () => {

//--------------------------------------------------------------------------------------------------
//# Functions

  function mouseOver(event) {
    let element = document.getElementById(event.target.id);
    element.style.transform = 'scale(1.06)';
  }

  function mouseLeave(event) {
    let element = document.getElementById(event.target.id);
    element.style.transform = 'scale(1.0)';
  }









//--------------------------------------------------------------------------------------------------
//# HTML

  return (
    <div className='break_commune'>
      <div>
        <div className='break_communeTitleContainer'>
          <marquee className='break_communeMarqueeText'>♈︎♉︎♊︎♋︎♌︎♍︎♎︎♏︎♐︎♑︎♒︎♓︎♈︎♉︎♊︎♋︎♌︎♍︎♎︎♏︎♐︎♑︎♒︎♓︎♈︎♉︎♊︎♋︎♌︎♍︎♎︎♏︎♐︎♑︎♒︎♓︎♈︎♉︎♊︎♋︎♌︎♍︎♎︎♏︎♐︎♑︎♒︎♓︎♈︎♉︎♊︎♋︎♌︎♍︎♎︎♏︎♐︎♑︎♒︎♓︎♈︎♉︎♊︎♋︎♌︎♍︎♎︎♏︎♐︎♑︎♒︎♓︎</marquee>
          <div className='break_communeTitleText' data-aos="zoom-out-up">Commune with the Cosmos</div>
          <marquee className='break_communeMarqueeText' direction="right">♈︎♉︎♊︎♋︎♌︎♍︎♎︎♏︎♐︎♑︎♒︎♓︎♈︎♉︎♊︎♋︎♌︎♍︎♎︎♏︎♐︎♑︎♒︎♓︎♈︎♉︎♊︎♋︎♌︎♍︎♎︎♏︎♐︎♑︎♒︎♓︎♈︎♉︎♊︎♋︎♌︎♍︎♎︎♏︎♐︎♑︎♒︎♓︎♈︎♉︎♊︎♋︎♌︎♍︎♎︎♏︎♐︎♑︎♒︎♓︎♈︎♉︎♊︎♋︎♌︎♍︎♎︎♏︎♐︎♑︎♒︎♓︎</marquee>
        </div>
      </div>
    </div>
  )
}

export default Break_Commune