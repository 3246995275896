//--------------------------------------------------------------------------------------------------
//# Imports

import React, {useEffect} from 'react'

import banner_image from '../../image/banner-home.webp'

import './banner.css'

//AppStart
const Banner = () => {
  







//--------------------------------------------------------------------------------------------------
//# HTML
  return (
    <div className='banner'>
      <div className='bannerContainer'>
        <img data-aos="zoom-in" src={banner_image} alt='' className='bannerImage' id='bannerImage' />
      </div>
    </div>
  )
}

export default Banner