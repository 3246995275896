//--------------------------------------------------------------------------------------------------
//# Imports

import React, { useEffect, useState } from 'react';
import Aos from "aos";
import "aos/dist/aos.css";

import avatarOverview_image from '../../image/avataroverview-image.webp'
import avataroverview_video from '../../image/avataroverview-video.mp4'

import './avataroverview.css'











//--------------------------------------------------------------------------------------------------
//# Variables

const delay_gap = 300;
let reverse_interval;
let mobile = window.innerWidth <= 600;
let contentLoaded = true;
let playing_forward = false;








//AppStart
const AvatarOverview = () => {

//--------------------------------------------------------------------------------------------------
//# Functions

  // Reset AOS timings after animation finishes so that mouseover animations are responsive
  useEffect(() => {
    Aos.init({ duration: 2000 });
    setTimeout(() => {
      const video_element = document.getElementById("avatarOverviewVideo");
      // video_element.style.transform = 'scale(1.0)';
      const helper_text = document.getElementById('avatarOverviewHelperText');
        video_element.addEventListener('mouseenter', () => {
          helper_text.style.transition = 'opacity 1.5s ease-in-out';
          helper_text.style.opacity = "0";
          // helper_text.style.transform = 'scale(1.0)';
        });
        video_element.addEventListener('mouseleave', () => {
          helper_text.style.transition = 'opacity 1.5s ease-in-out';  
          helper_text.style.opacity = "1";
          // helper_text.style.transform = 'scale(1.0)';
        });
      }, 1000);  // Match to AOS duration
  }, []);

  function mouseOver(event) {
    if (!mobile) {
      playForward();
    };
  };
  
  function mouseLeave(event) {
    playReverse();
  };

  function handleClick(event) {
    console.log('onClick:', document.getElementById(event.target.id));
    if (mobile) {
      if (!playing_forward) {
        playForward();
      } else {
        playReverse();
      };
    };
  };

  function playForward () {
    const video_element = document.getElementById("avatarOverviewVideo");
    clearInterval(reverse_interval);
    video_element.play()
      .catch(error => {
        if (error.name === "NotAllowedError") {
          video_element.controls = true;
        }
      }
    );
    
    video_element.controls = false;
    playing_forward = true;

    // Hide helper text during playback
    document.getElementById('avatarOverviewHelperText').style.opacity = "0%";
  }
    
  function playReverse() {
    const video_element = document.getElementById("avatarOverviewVideo");
    video_element.pause();
    clearInterval(reverse_interval);  // Prevent multiple intervals

    reverse_interval = setInterval(() => {
      if (video_element.currentTime <= 0) {
        clearInterval(reverse_interval); // Stop when video reaches the beginning
      } else {
        video_element.currentTime -= 0.10;  // Reverse playback
      }
    }, 50);

    video_element.controls = false;
    playing_forward = false;

    // Reveal helper text after playback
    document.getElementById('avatarOverviewHelperText').style.opacity = "100%";
  };








//--------------------------------------------------------------------------------------------------
//# HTML
  return (
    <div className='avatarOverview'>
      {!contentLoaded ? (
        <p>Loading...</p>
      ) : (
      <div className='avatarOverview'>
      <div className='avatarOverviewLeftContainer'>
        <div className='avatarOverviewTextContainer'>
          <div className='avatarOverviewText avatarOverviewTitle avatarOverviewTextTop' data-aos="fade-down-right" data-aos-delay={1 * delay_gap}>Forge Your Alchm Avatar</div>
          <div className='avatarOverviewText' data-aos="fade-right" data-aos-delay={2 * delay_gap}>If your astral self came to life, what would they look like? What would they do?
            {!mobile && <br />}
            <span style={{ color: 'var(--color-main)' }}> Who would they be?</span></div>
          <div className='avatarOverviewText  avatarOverviewTextBottom' data-aos="fade-up-right" data-aos-delay={3 * delay_gap}>Generate your personalized Alchm Avatar
            {!mobile && <br />}
             and <span style={{ color: 'var(--color-main)' }}>forge your destiny.</span></div>
        </div>
      </div>
      <div className='avatarOverviewRightContainer'>
        <div className='avatarOverviewImageContainer'>
        <video data-aos="flip-left" src={avataroverview_video} alt='' className='avatarOverviewVideo' id='avatarOverviewVideo' onMouseOver={mouseOver} onMouseLeave={mouseLeave} onClick={handleClick}
          muted loop playsInline loading="lazy"/>
        </div>
        <div className='avatarOverviewHelperTextContainer'> 
          {mobile ? (
            <div className='avatarOverviewHelperText' id='avatarOverviewHelperText' data-aos="fade-left" data-aos-delay={4 * delay_gap}>↑ Tap to Discover</div>
          ) : (
            <div className='avatarOverviewHelperText' id='avatarOverviewHelperText' data-aos="fade-left" data-aos-delay={4 * delay_gap}>↑ Mouse over to Discover</div>
          )}
        </div>
      </div>
      </div>
      )}
    </div>
  )
}

export default AvatarOverview
