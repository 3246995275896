import React, { useEffect } from 'react';

import Aos from "aos";
import "aos/dist/aos.css";

import './footer.css'

import nofun_logo_title_image from '../../image/nofun-logo-title.png';
import powered_by_image from '../../image/powered-by.PNG';

const Footer = () => {
  
  
  useEffect(() => {
    Aos.init({ duration: 1500 });
  }, []);


  function onMouseOver(event) {
    let element = document.getElementById(event.target.id);
    element.style.transform = 'scale(1.05)';
  };
  
  function onMouseLeave(event) {
    let element = document.getElementById(event.target.id);
    element.style.transform = 'scale(1.0)';
  };


  return (
    <div className='footer'>
      <div className='footerContainer'>
        <div className='poweredByContainer' id='poweredByContainer'>
          <img src={powered_by_image} alt="Powered by NoFun Labs" className='poweredByImage' id='poweredByTextImage'/>
          <a href='https://nofunz.one/twitter-alchmref' target='_blank'>
            <img onMouseOver={onMouseOver} onMouseLeave={onMouseLeave} src={nofun_logo_title_image} alt="Powered by NoFun Labs" className='poweredByImage footerButton' id='poweredByImage'/>
          </a>
        </div>
        <div className='footerMarqueeText'>
          <marquee>Learn more in our documentation: <a style={{color: "var(--color-main)"}} href="https://evanon.earth/alchmdocs-alchmref" target="_blank">docs.alchm.xyz</a><span style={{letterSpacing: "20px"}}> ♈︎♉︎♊︎♋︎♌︎♍︎♎︎♏︎♐︎♑︎♒︎♓︎</span> Only email is required for whitelist & beta access: <a style={{color: "var(--color-main)"}} href="https://evanon.earth/alchmbetasignup-siteref" target="_blank">click here</a><span style={{letterSpacing: "20px"}}> ♈︎♉︎♊︎♋︎♌︎♍︎♎︎♏︎♐︎♑︎♒︎♓︎</span> Connect with us on social media! :D | <a style={{color: "var(--color-main)"}} href="https://evanon.earth/alchmx-alchmref" target="_blank">X</a> | <a style={{color: "var(--color-main)"}} href="https://evanon.earth/alchminstagram-alchmref" target="_blank">Instagram</a> | <a style={{color: "var(--color-main)"}} href="https://alchm.xyz/" target="_blank">Discord (coming soon)</a> |<span style={{letterSpacing: "20px"}}> ♈︎♉︎♊︎♋︎♌︎♍︎♎︎♏︎♐︎♑︎♒︎♓︎</span></marquee>
        </div>
      </div>
      {/* Runic Characters: ᚨᛗᛅᛖᛏ */}
      {/* <div className='creatorAttributionContainer'>
        <div className='creatorAttributionText'>Site created by </div><a className='creatorAttributionLink' href="https://twitter.com/EvanOnEarth_eth">@EvanOnEarth_eth</a>
      </div> */}
    </div>
  )
}

export default Footer