import React from 'react'

import './roadmap.css'

import roadmap_image from '../../image/roadmap-image.webp'

const Roadmap = () => {

  function mouseOver(event) {
    let element = document.getElementById(event.target.id);
    element.style.transform = 'scale(1.06)';
  }
  
  function mouseLeave(event) {
    let element = document.getElementById(event.target.id);
    element.style.transform = 'scale(1.0)';
  }

  return (
    <div className='roadmap'>
      <div className='roadmapContainer'>
        <img data-aos="flip-left" src={roadmap_image} alt='' className='roadmapImage' id='roadmapImage' onMouseOver={mouseOver} onMouseLeave={mouseLeave}/>
      </div>
    </div>
  )
}

export default Roadmap