//--------------------------------------------------------------------------------------------------
//# Imports

import React from 'react'

import './break_astral.css'









//--------------------------------------------------------------------------------------------------
//# Variables











//AppStart
const Break_Astral = () => {

//--------------------------------------------------------------------------------------------------
//# Functions

  function mouseOver(event) {
    let element = document.getElementById(event.target.id);
    element.style.transform = 'scale(1.06)';
  }

  function mouseLeave(event) {
    let element = document.getElementById(event.target.id);
    element.style.transform = 'scale(1.0)';
  }









//--------------------------------------------------------------------------------------------------
//# HTML

  return (
    <div className='break_astral'>
      <div>
        <div className='break_astralTitleContainer'>
          <marquee className='break_astralMarqueeText'>♈︎♉︎♊︎♋︎♌︎♍︎♎︎♏︎♐︎♑︎♒︎♓︎♈︎♉︎♊︎♋︎♌︎♍︎♎︎♏︎♐︎♑︎♒︎♓︎♈︎♉︎♊︎♋︎♌︎♍︎♎︎♏︎♐︎♑︎♒︎♓︎♈︎♉︎♊︎♋︎♌︎♍︎♎︎♏︎♐︎♑︎♒︎♓︎♈︎♉︎♊︎♋︎♌︎♍︎♎︎♏︎♐︎♑︎♒︎♓︎♈︎♉︎♊︎♋︎♌︎♍︎♎︎♏︎♐︎♑︎♒︎♓︎</marquee>
          <div className='break_astralTitleText' data-aos="zoom-out-up">Connect with your Astral Self</div>
          <marquee className='break_astralMarqueeText' direction="right">♈︎♉︎♊︎♋︎♌︎♍︎♎︎♏︎♐︎♑︎♒︎♓︎♈︎♉︎♊︎♋︎♌︎♍︎♎︎♏︎♐︎♑︎♒︎♓︎♈︎♉︎♊︎♋︎♌︎♍︎♎︎♏︎♐︎♑︎♒︎♓︎♈︎♉︎♊︎♋︎♌︎♍︎♎︎♏︎♐︎♑︎♒︎♓︎♈︎♉︎♊︎♋︎♌︎♍︎♎︎♏︎♐︎♑︎♒︎♓︎♈︎♉︎♊︎♋︎♌︎♍︎♎︎♏︎♐︎♑︎♒︎♓︎</marquee>
        </div>
      </div>
    </div>
  )
}

export default Break_Astral